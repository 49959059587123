import LocalGridConfig from "../../../../../config/LocalGridConfig";
import { AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import useStore from "../../../../../store";
import { observer } from "mobx-react";
import { Button, Switch } from "antd";
import { vsmCommon } from "../../../../../config/messages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEye,
	faPencilAlt,
	faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { CurrencyFormat } from "../../../../../utils/GlobalFunction";

const ActionRenderer = observer((props) => {
	const { AUTH } = useStore();
	const {
		openEditModal,
		openDeleteModal,
		openViewModal,
		openFreeChassisModal,
	} = props.agGridReact.props.frameworkComponents;

	return (
		<div className="action-column">
			{(AUTH.checkPrivileges("#6220#") || AUTH.checkPrivileges("#8010#") || AUTH.checkPrivileges("#8160#") || AUTH.checkPrivileges("#8187#") || AUTH.checkPrivileges("#8195#") || AUTH.checkPrivileges("#8255#") || AUTH.checkPrivileges("#8310#")) && (
				<Button
					type="text"
					title={"View"}
					className="viewIcon mr-10"
					size="large"
					style={{ padding: 7 }}
					onClick={() => {
						openViewModal(props.data);
					}}
				>
					<FontAwesomeIcon icon={faEye} />
				</Button>
			)}
			{AUTH.checkPrivileges("#6210#") && (
				<Button
					type="text"
					title={"Edit"}
					className="editIcon mr-10"
					size="large"
					style={{ padding: 7 }}
					disabled={props.data.is_used === 1 ? true : false}
					onClick={() => {
						openEditModal(props.data);
					}}
				>
					<FontAwesomeIcon icon={faPencilAlt} />
				</Button>
			)}
			{(AUTH.checkPrivileges("#6210#") && props.data.chassis_flag === 1) && (
				<Button
					type="text"
					title={"Add/Remove Free Chassis"}
					className="resignIcon mr-10 resignIcon-align"
					size="large"
					style={{ padding: 7 }}
					disabled={props.data.is_used === 1 ? true : false}
					onClick={() => {
						openFreeChassisModal(props.data);
					}}
				>
					<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 980 980" preserveAspectRatio="xMidYMid meet"><path d="M463 .7C297.5 10.2 147.8 103.1 64.6 247.9 30.4 307.3 10.1 370.8 2.3 442c-2.5 23.1-2.5 72.9 0 96 10 92.1 41.6 172.8 96.2 245.5C204.4 924.8 377.4 998.1 552 975.9c65.3-8.3 130.8-30.8 186.5-64.1 125.3-75 210.8-199.1 234.5-340.3 5.2-31.1 6.4-46 6.4-81.5 0-35.6-1.2-50.4-6.5-81.9-22.3-133.1-101.3-253.2-216-328.5C677.1 27.3 581.8-.8 487.2.2L463 .7zM367 245v49h49 49v37 37h-49-49v49 49h-36.5H294v-49-49h-49-49v-37-37h49 49v-49-49h36.5H367v49zm413-22.3c56.1 65.1 89.3 139.8 99.7 224.3 2.4 20.2 2.4 65.6-.1 86-9.5 78-38.1 145.5-87.4 206-11.9 14.6-41.2 43.6-56.2 55.6-60.1 48-125.9 75.6-203 85.1-20.3 2.5-65.7 2.5-86 0-85.2-10.5-159.1-43.5-225.7-101-5.7-4.9-10.3-9.1-10.3-9.4 0-.9 557.3-558.2 558.2-558.3.4 0 5.3 5.3 10.8 11.7zM490 649.5V686h122.5H735v-36.5V613H612.5 490v36.5z"/></svg>
				</Button>
			)}
			{(AUTH.checkPrivileges("#6225#") && props.data.is_used === 0) && (
				<Button
					type="text"
					title={"Delete"}
					className="deleteIcon"
					size="large"
					style={{ padding: 7 }}
					disabled={props.data.is_used === 1 ? true : false}
					onClick={() => {
						openDeleteModal(props.data);
					}}
				>
					<FontAwesomeIcon icon={faTrashAlt} />
				</Button>
			)}
		</div>
	);
});

const PackageDefListComponent = observer((props) => {
	const {
		openEditModal,
		openViewModal,
		openDeleteModal,
		onSwitchChange,
		openFreeChassisModal
	} = props;
	const {
		ManagePackageDefStore: { list_data, setupGrid, onFilterChanged, },
		AUTH,
	} = useStore();

	const gridOptions = {
		columnDefs: [
			{
				headerName: "# ID",
				field: "srno",
				filter: "agNumberColumnFilter",
				pinned: "left",
				minWidth: 120,
				width: 120,
			},
			{
				headerName: "Variant",
				field: "variant.name",
			},
			{
				headerName: "Ex showroom",
				field: "ex_showroom",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.ex_showroom} />
				},
			},
			{
				headerName: "CSD Ex-showroom",
				field: "csd_ex_showroom",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return (params.data && params.data.csd_ex_showroom) ? <CurrencyFormat value={params.data.csd_ex_showroom} /> : "N/A"
				},
			},
			{
				headerName: "RTO (Individual)",
				field: "rto_amount",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return (params.data && params.data.rto_amount) ? <CurrencyFormat value={params.data.rto_amount} /> : "N/A"
				},
			},
			{
				headerName: "RTO (Company)",
				field: "rto_amount_comp",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return (params.data && params.data.rto_amount_comp) ? <CurrencyFormat value={params.data.rto_amount_comp} /> : "N/A"
				},
			},
			{
				headerName: "PMS",
				field: "pms_amount",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return (params.data && params.data.pms_amount) ? <CurrencyFormat value={params.data.pms_amount} /> : "N/A"
				},
			},
			{
				headerName: "Handling",
				field: "handling_amount",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return (params.data && params.data.handling_amount) ? <CurrencyFormat value={params.data.handling_amount} /> : "N/A"
				},
			},
			{
				headerName: "Color",
				field: "color_flag_name",
				filter: "agSetColumnFilter",
			},
			{
				headerName: "Corporate",
				field: "corporate_benefit_flag_name",
				filter: "agSetColumnFilter",
			},
			{
				headerName: "Finance",
				field: "fin_flag_name",
				filter: "agSetColumnFilter",
			},
			{
				headerName: "Insurance",
				field: "ins_flag_name",
				filter: "agSetColumnFilter",
			},
			{
				headerName: "Extended Warranty",
				field: "ew_flag_name",
				filter: "agSetColumnFilter",
			},
			{
				headerName: "Accessories",
				field: "accessory_flag_name",
				filter: "agSetColumnFilter",
			},
			{
				headerName: "Scheme Disc.",
				field: "scheme_disc_flag_name",
				filter: "agSetColumnFilter",
			},
			{
				headerName: "Is used",
				field: "is_used_name",
				filter: "agSetColumnFilter",
			},
			{
				headerName: "Active",
				field: "status_name",
				filter: "agSetColumnFilter",
				hide: AUTH.checkPrivileges("#6215#") ? false : true,
				cellRendererFramework: function (data) {
					const { onSwitchChangeGrid } = data.agGridReact.props;
					return (
						<Switch
							defaultChecked={data.data.status}
							onChange={(val) => onSwitchChangeGrid(val, data.data)}
						/>
					);
				},
			},
			{
				headerName: "Actions",
				field: "actions",
				type: "actionColumn",
				filter: false,
				sortable: false,
				pinned: "right",
				minWidth: 180,
				width: 180,
			},
		],
	};

	return (
		<div className="ag-theme-alpine grid_wrapper">
			<AgGridReact
				rowHeight={LocalGridConfig.rowHeight}
				headerHeight={LocalGridConfig.headerHeight}
				rowData={list_data}
				modules={AllModules}
				columnDefs={gridOptions.columnDefs}
				defaultColDef={LocalGridConfig.defaultColDef}
				columnTypes={LocalGridConfig.columnTypes}
				overlayNoRowsTemplate={vsmCommon.noRecord}
				frameworkComponents={{
					ActionRenderer,
					openDeleteModal,
					openEditModal,
					openViewModal,
					openFreeChassisModal,
				}}
				onGridReady={setupGrid}
				gridOptions={LocalGridConfig.options}
				onFilterChanged={onFilterChanged}
				onSortChanged={onFilterChanged}
				onSwitchChangeGrid={onSwitchChange}
			/>
		</div>
	);
});

export default PackageDefListComponent;
